<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        SW900 iV Clients
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <!-- <button
        @click="addNote"
        class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path d="M12 5v14M5 12h14" />
        </svg>
        <span>Add Comment</span>
      </button> -->
    </div>
    <div class="">
      <div v-if="viewState == 'Idle'">
        <Table
          v-if="ivyActivations.length > 0"
          :columns="columns"
          :data="ivyActivations"
          :totalRecords="paginationOptions.totalRecords"
          :hasPagination="true"
          :currentPage="paginationOptions.currentPage"
          :totalPages="paginationOptions.totalPages"
          :initialSortOptions="sortOptions"
          @onSortChanged="onTableSortChanged"
          @onPageChanged="onTablePageChanged"
        />
        <p v-else>No SW900 iV Clients</p>
      </div>
    </div>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";

export default {
  name: "IvyActivations",
  components: { Table },
  props: {
    vtmsOrgId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      viewState: "Idle",
      ivyActivations: [],
      paginationOptions: {
        currentPage: 1,
        totalPages: 1,
        totalRecords: 0,
      },
      sortOptions: {
        field: "activated_at",
        isAsc: false,
        pageSize: 50,
      },
      columns: [
        {
          title: "Client Name",
          field: "client_name",
          selector: (row) => row.client_name,
        },
        {
          title: "VTS Number",
          field: "vts_number",
          selector: (row) => row.vts_number,
        },
        {
          title: "Activated At",
          field: "activated_at",
          selector: (row) => row.activated_at,
          type: "date",
        },
      ],
    };
  },
  async mounted() {
    try {
      this.viewState = "Loading";
      await this.loadActivations();
      this.viewState = "Idle";
    } catch (error) {
      console.error(error);
      this.viewState = "Error";
      this.error = error;
    }
  },
  methods: {
    loadActivations: async function() {
      this.viewState = "Loading";
      console.log("[IvyActivations] loadActivations");
      try {
        let result = await this.ContactService.getIvyClients(this.vtmsOrgId, {
          ...this.paginationOptions,
          ...this.sortOptions,
        });
        console.log("[IvyActivations] loadActivations", result);
        this.paginationOptions.currentPage = result.page;
        this.paginationOptions.totalPages = result.page_count;
        this.paginationOptions.totalRecords = result.count;
        this.ivyActivations = result.data;
        // this.ivyActivations = [
        //   {
        //     client_name: "Client Name 1",
        //     activated: true,
        //     vts_number: "11235",
        //     activated_at: 1613574623,
        //   },
        // ];
      } catch (error) {
        console.error(error);
      } finally {
        this.viewState = "Idle";
      }
    },
    onTableSortChanged: async function(sortOptions) {
      console.log(
        "[IvyActivationsTab] onTableSortChanged: Changed Sort: ",
        sortOptions
      );
      this.sortOptions = sortOptions;
      await this.loadActivations();
    },
    onTablePageChanged: async function(direction) {
      console.log(
        "[IvyActivationsTab] onTablePageChanged: Page changed in the following direction: ",
        direction
      );
      this.paginationOptions.currentPage =
        this.paginationOptions.currentPage + direction;
      await this.loadActivations();
    },
  },
};
</script>
